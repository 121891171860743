<template>
    <div>
        <b-row>
            <b-col>
                <b-card title="Create New Settlement" sub-title="Create new settlement to wallet or bank account">
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label="Available Balance">
                                    <b-form-input :value="`RM ${agent.balance.toFixed(2)}`" size="lg" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Bank Name">
                                    <b-form-input :value="agent.bankName" size="lg" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Bank Account Number">
                                    <b-form-input :value="agent.accountNumber" size="lg" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Bank Account Name">
                                    <b-form-input :value="agent.accountName" size="lg" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Amount" label-for="amount">
                                    <b-form-input id="amount" size="lg" v-model="form.amount" type="number" placeholder="Enter amount"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button variant="primary" size="lg" @click="onSubmitBtnHandler">Submit</b-button>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BFormCheckbox, BFormFile, BFormGroup, BFormInput, BFormSelect, BPagination, BRow } from 'bootstrap-vue';

export default {
    name: "AddSettlement",
    components: {
        BCard,
        BCardText,
        BRow, BCol, BCardBody, BButton, BFormCheckbox, BFormInput, BFormSelect, BFormGroup, BButton, BPagination, BCardHeader, BFormFile
    },
    data() {
        return {
            form: {
                amount: null,
            },
            agent: {
                balance: 0.00,
                bankName: "",
                accountNumber: "",
                accountName: ""
            }
        }
    },
    async mounted() {
    },
    methods: {
        async getAccountHandler() {
            const accounts = await getAccounts(this.form.partner);
            this.accounts = accounts.map((account) => {
                return {
                    value: account.id,
                    text: account.name,
                    currency: account.currency,
                    balance: account.balance,
                }
            });
        },
        async onSubmitBtnHandler() {
            try {
                await createSettlement(this.form);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Success!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
                this.$router.push('/settlements');
            }
            catch (e) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Save Failed!',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        //get res error response body
                        text: e.response.data.message
                    },
                });
            }
        }
    }
}
</script>